import { motion } from "framer-motion"
import "./App.css"
import GameSlidePage from "./pages/GameSlidePage"

const App = () => {
  return (
    <div className="App">
      <div className="App-header">
        {/* <motion.div whileDrag={{ scale: 1.2 }}> */}
          <GameSlidePage />
        {/* </motion.div> */}
      </div>
    </div>
  )
}

export default App
