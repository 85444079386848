import type { GameLogicStatic } from "./types";

// Templates Strings
// Stats {$age}, {$choices}, {$duration}
// Meters {$education}, {$relationship}, {$health}\

const defaultGameData: GameLogicStatic = {
  rules: {
    age: {lt: 25},
    education: {gt: 0, lt: 100},
    relationship: {gt: 0, lt: 100},
    health: {gt: 0, lt: 100},
  },
  start: {
    turn: 0,
    duration: 0,
    age: 0,
    education: 25,
    relationship: 25,
    health: 25,
    slide: {
      title: { en: "Welcome to My Choices" },
      description: { en: "Press Continue" },
      image: "https://via.placeholder.com/150",
      imageAlt: "placeholder",
      type: "choice",
      selections: [
        {
          title: { en: "Continue" },
          icon: "positive",
        },
      ],
    },
    tags: [],
  },
  content: [
    // {
    //   title: "Example Content",
    //   slides: [
    //     {
    //       title: { en: "Brush your teeth" },
    //       description: { en: "Sometimes you have to brush your teeth" },
    //       image: "https://via.placeholder.com/150",
    //       imageAlt: "placeholder",
    //       type: "choice",
    //       requirements: {
    //         age: { gt: 1 },
    //       },
    //       selections: [
    //         {
    //           title: { en: "Yes" },
    //           icon: "positive",
    //           affects: {
    //             health: 10,
    //           },
    //         },
    //         {
    //           title: { en: "No" },
    //           icon: "negative",
    //           affects: {
    //             health: -10,
    //           },
    //         },
    //       ],
    //     },
    //     // {
    //     //   requirements: { tags: { not: ["{$age}_birthday"] } },
    //     //   title: { en: "Birthday Party" },
    //     //   description: {
    //     //     en: "It's your birthday, do you want to have a party?",
    //     //   },
    //     //   image: "https://via.placeholder.com/150",
    //     //   imageAlt: "placeholder",
    //     //   type: "event",
    //     //   selections: [
    //     //     {
    //     //       title: { en: "Yes" },
    //     //       affects: {
    //     //         health: 10,
    //     //         tags: { add: ["{$age}_birthday"] },
    //     //       },
    //     //     },
    //     //   ],
    //     // },
    //   ],
    // },
    {
      title: "Default Content",
      slides: [],
      triggers: [
        {
          title: { en: "Mother" },
          description: { en: "You have a child" },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "trigger",
          requirements: {
            tags: { has: "is_pregnant" },
          },
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                health: -10,
              },
            },
          ],
        },
        {
          title: { en: "School Dropout" },
          description: { en: "You drop out of school" },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "trigger",
          requirements: {
            tags: { has: "is_school_dropout" },
          },
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                education: -10,
              },
            },
          ],
        }
      ]
    },
    {
      title: "Starting Events",
      age: 0,
      requirements: {
        age: { eq: 0 },
      },
      slides: [
        {
          title: { en: "Wealth" },
          description: { en: "You are born to wealthy parents." },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                health: 10,
              },
            },
          ],
        },
        {
          title: { en: "Abuse" },
          description: { en: "You have an abusive parent." },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                relationship: -10,
              },
            },
          ],
        },
        {
          title: { en: "Under Educated" },
          description: { en: "Your parents are under educated." },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                education: -10,
              },
            },
          ],
        },
        {
          title: { en: "Health Condition" },
          description: { en: "You are born with a health condition." },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                health: -10,
              },
            },
          ],
        },
        {
          title: { en: "Well Educated" },
          description: { en: "Your parents are well educated." },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                education: 10,
              },
            },
          ],
        },
        {
          title: { en: "Supportive" },
          description: { en: "Your parents are supportive." },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                relationship: 10,
              },
            },
          ],
        },

      ],
    },
    {
      title: "Early Primary School ",
      requirements: {
        age: { gt: 1, lt: 8 },
      },
      slides: [
        {
          title: { en: "Early Primary School" },
          description: { en: "You are in Early Primary School" },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                relationship: 10,
              },
            },
          ],
        },
      ],
    },
    {
      title: "Late Primary School",
      age: 12,
      requirements: {
        age: { gt: 9, lt: 12 },
      },
      slides: [
        {
          title: { en: "Late Primary School" },
          description: { en: "You are in Late Primary School" },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                relationship: 10,
              },
            },
          ],
        },


      ],
    },
    {
      title: "Early Secondary School",
      age: 15,
      requirements: {
        age: { gt: 13, lt: 15 },
      },
      slides: [
        {
          title: { en: "Early Secondary School" },
          description: { en: "You are in Early Secondary School" },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                relationship: 10,
              },
            },
          ],
        }

      ],
    },
    {
      title: "Late Secondary School",
      age: 18,
      requirements: {
        age: { gt: 16, lt: 18 },
      },
      slides: [
        {
          title: { en: "Late Secondary School" },
          description: { en: "You are in Late Secondary School" },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                relationship: 10,
              },
            },
          ],
        },
      ],
    },
    {
      title: "Early Adulthood",
      age: 21,
      requirements: {
        age: { gt: 19, lt: 21 },
      },
      slides: [
        {
          title: { en: "Early Adulthood" },
          description: { en: "You are in Early Adulthood" },
          image: "https://via.placeholder.com/150",
          imageAlt: "placeholder",
          type: "event",
          selections: [
            {
              title: { en: "Continue" },
              affects: {
                relationship: 10,
              },
            },
          ],
        },
      ],
    },
  ],
};

export default defaultGameData;
