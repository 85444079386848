import { useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCharacterName, updateCharacterName } from "./characterSlice";
import { IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Input, Text } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

export const CharacterText = () => {
  const characterName = useAppSelector(selectCharacterName);
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputText, setInputText] = useState(characterName);

  const handleEditClick = () => {
    setIsModalOpen(true);
    setInputText(characterName); // Initialize input text with the current character name
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveClick = () => {
    dispatch(updateCharacterName(inputText));
    setIsModalOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value);
  };

  return (
    <div>
      <h1><Text as='u'>{characterName}</Text> <IconButton size="xs" aria-label="Edit character name" icon={<EditIcon />} onClick={handleEditClick} /></h1>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Your Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input type="text" value={inputText} onChange={handleInputChange} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveClick}>
              Save
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
