import type { BaseSelection, GameLogicContent, GameLogicRequirements, GameLogicState, GameLogicTypes } from '../features/types';

// Function to check if the current value meets the given requirement (gt, lt, eq, not)
const meetsRequirement = (value: number, requirement?: GameLogicTypes): boolean => {
  if (requirement == undefined) return true;
  if ('gt' in requirement && value < requirement.gt) return false;
  if ('lt' in requirement && value > requirement.lt) return false;
  if ('eq' in requirement && value !== requirement.eq) return false;
  if ('not' in requirement && value === requirement.not) return false;
  if ('gt' in requirement && 'lt' in requirement && (value < requirement.gt || value > requirement.lt)) return false;

  return true;
};

// Function to check tag requirements
const meetsTagRequirements = (tags: string[], requirementTags?: { not?: string[]; or?: string[]; and?: string[]; has?: string }) => {
  if (!requirementTags) return true;
  if (requirementTags.not && requirementTags.not.some(tag => tags.includes(tag))) return false;
  if (requirementTags.and && !requirementTags.and.every(tag => tags.includes(tag))) return false;
  if (requirementTags.or && !requirementTags.or.some(tag => tags.includes(tag))) return false;
  if (requirementTags.has && !tags.includes(requirementTags.has)) return false;

  return true;
};

const processRequirements = (requirements: GameLogicRequirements | undefined, currentState: GameLogicState) => {
  const { age, education, health, relationship, tags } = currentState;
  if (requirements) {
    if (!meetsRequirement(age, requirements.age)) return false;
    if (!meetsRequirement(education, requirements.education)) return false;
    if (!meetsRequirement(health, requirements.health)) return false;
    if (!meetsRequirement(relationship, requirements.relationship)) return false;
    if (!meetsTagRequirements(tags, requirements.tags)) return false;
  }
  return true;
}


// Main Function to Filter and Select a Slide
export const getValidSlides = (input: GameLogicContent, currentState: GameLogicState) => {
  // Filter all slides that meet the requirements
  const allValidSlides = input.flatMap((entry) => {
    const { requirements, slides } = entry;
    const isValidEntry = processRequirements(requirements, currentState);
    if (isValidEntry) {
      return slides.filter((slide) => processRequirements(slide.requirements, currentState));
    }
    return [];
  });

  // Gather all possible slides from valid entries
  console.log("Found " + allValidSlides.length + " valid slides");
  console.log(allValidSlides.map((slide) => slide.title.en));

  return allValidSlides;
};


export const getRandomValidSlide = (input: GameLogicContent, currentState: GameLogicState) => {
  const allValidSlides = getValidSlides(input, currentState);

   // Randomly select one slide from the valid slides
   if (allValidSlides.length === 0) return null;
   const randomIndex = Math.floor(Math.random() * allValidSlides.length);
   return allValidSlides[randomIndex];
}

// Check game rules
export const checkGameRules = (currentState: GameLogicState, rules: GameLogicRequirements) => {
  const { age, education, health, relationship } = currentState;
  if (!meetsRequirement(age, rules.age)) return false;
  if (!meetsRequirement(education, rules.education)) return false;
  if (!meetsRequirement(health, rules.health)) return false;
  if (!meetsRequirement(relationship, rules.relationship)) return false;
  return true;
};

// Check selection consquences
export const processTurn = (currentState: GameLogicState, selection: BaseSelection) => {
  const newState = { ...currentState };
  if (selection.affects) {
    if (selection.affects.age) newState.age += selection.affects.age;
    if (selection.affects.education) newState.education += selection.affects.education;
    if (selection.affects.health) newState.health += selection.affects.health;
    if (selection.affects.relationship) newState.relationship += selection.affects.relationship;
  }
  return newState;
};