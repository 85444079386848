import {
  CheckCircleIcon,
  WarningTwoIcon,
  MoonIcon,
  SunIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons"
import { createIcon } from "@chakra-ui/icons"
import type { GameIconList } from "../features/types"

export const FireIcon = createIcon({
  displayName: "FireIcon",
  viewBox: "-30 -30 60 60",
  d: "M15.3-10.2q6.6 9.4 5.55 18.85v.25q-1.3 7.85-5.8 13.1-2.15 3.6-6.7 4.9l.05-.15q1.2-2.9-.05-8.05l-.05-.05Q4.15 7.1.35 4.05q-.7-.6-1.45-.45-.95.05-1.45.85-.45.55-.4 1.4v.05q.1 4.75-2.75 8.75-3.55 3.6-3.1 8l.05.1q.4 2.55 1.65 4.25-11.8-3-13.8-15.15-.95-8.75 6.1-15.95 7.15-9.6 6.8-21.05-.1-1.15.55-1.9.55-.85 1.5-.9.85-.15 1.6.5Q6.55-18.25 10.1-5.3q.85-1.75 1.1-3.75.1-1.1.95-1.75.7-.6 1.7-.4.85.15 1.45 1",
})

export const HeartIcon = createIcon({
  displayName: "HeartIcon",
  viewBox: "-30 -30 60 60",
  d: "M21.05 -16 Q25 -12.05 25 -6.4 25 -0.85 21.05 3.15 L21 3.2 20.95 3.25 19 4.85 1.6 19.45 Q0.9 20 0 20 -0.85 20 -1.5 19.45 L-18.9 4.85 Q-19.95 4.15 -20.8 3.25 L-20.9 3.15 Q-25 -0.85 -25 -6.4 -25 -12.05 -20.9 -16 -16.95 -20 -11.3 -20 -5.6 -20 -1.55 -16 L0.05 -14.15 1.7 -16 Q5.65 -20 11.3 -20 17 -20 21.05 -16",
})

export const BookIcon = createIcon({
  displayName: "BookIcon",
  viewBox: "-30 -30 60 60",
  d: "M-1 24.85 Q-6.75 21.5 -19.05 21 -21.85 20.9 -25.05 20.95 L-26.95 21 -27.3 21 -27.95 21 -28 21 -28.3 21 -28.95 21 -29.95 20.75 -30.7 20.05 -31 19 -31 -17 Q-31 -17.85 -30.4 -18.4 -29.85 -19 -29 -19 L-26 -19 -26 11 Q-26 12.2 -25.15 13.1 L-25.05 13.2 Q-24.25 13.95 -23.1 14 L-18.05 14.3 Q-6.65 15.25 -1.3 17.8 L0 18.1 1.35 17.8 Q6.4 15.35 16.95 14.35 L23.15 14 Q24.15 13.95 24.95 13.3 L25.15 13.1 Q26 12.2 26 11 L26 -19 29 -19 Q29.85 -19 30.45 -18.4 31 -17.85 31 -17 L31 19 30.75 20.05 30 20.75 28.95 21 28.3 21 28 21 27.95 21 27.3 21 26.95 21 24.95 20.95 17.95 21.05 Q6.55 21.7 1.05 24.85 L0 25.1 -1 24.85 M0 13.1 Q-5.75 10.25 -21 9.1 L-22 9 -22 -23 -20.9 -22.9 Q-10.85 -22.1 -6.25 -21 -2.65 -20.15 -0.2 -19 L0 -18.9 0.25 -19 Q2.7 -20.15 6.3 -21 10.15 -21.9 22 -23 L22 9 17.95 9.4 Q5.35 10.45 0 13.1",
})

export const lookupGameIcon = (icon: string | undefined) => {
  // Convert string to enum IconList
  const iconType = icon as GameIconList
  switch (iconType) {
    case "positive":
      return <CheckCircleIcon />
    case "negative":
      return <WarningIcon />
    case "warning":
      return <WarningTwoIcon />
    case "fire":
      return <FireIcon />
    case "heart":
      return <HeartIcon />
    case "sun":
      return <SunIcon />
    case "moon":
      return <MoonIcon />
    case "time":
      return <TimeIcon />
    default:
      return null
  }
}

export const GameIcon = (props: { icon: string | undefined }) => {
  return lookupGameIcon(props.icon)
}
<svg xmlns="http://www.w3.org/2000/svg"><path fill="#FFF" d=""/></svg>