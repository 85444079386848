import { motion } from 'framer-motion';

const MeterBar: React.FC<{ value: number, max: number, icon: React.ReactNode, modifier: number }> = ({ value, max, icon, modifier }) => {
  // Calculate the total percentage considering value and modifier
  const percentage = Math.min((value + modifier) / max * 100, 100); // Ensure it does not exceed 100%

  // Determine color based on modifier
  const color = modifier !== 0 ? '#ff6347' : '#008000'; // Use a different color when modifier is not zero

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {icon}
      <div style={{ border: '1px solid #000', width: '30px', height: '100px', borderRadius: '5px', display: 'flex', flexDirection: 'column-reverse' }}>
        <motion.div
          style={{
            width: '100%',
            borderRadius: '5px',
          }}
          animate={{ height: `${percentage}%`, backgroundColor: color }}
          initial={{ height: 0, backgroundColor: '#008000' }}
          transition={{
            height: { duration: 0.5 }, // Transition duration for height change
            backgroundColor: { duration: 0.5, ease: 'easeInOut' } // Smooth transition for color
          }}
        />
      </div>
    </div>
  );
};

export default MeterBar;
