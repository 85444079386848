import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Stack,
  Image,
} from "@chakra-ui/react"
import defaultGameData from "../default"
import type { BaseSelection } from "../types"
import { GameText } from "../../components/GameText"
import { GameHeader } from "../../components/GameHeader"
import { motion } from "framer-motion"
import { lookupGameIcon } from "../../components/GameIcon"
import { useGameTriggerContext } from "../../hooks/GameTriggers"
import { checkGameRules, getRandomValidSlide, processTurn } from "../../app/gameLogic"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  incrementGameTurn,
  resetGame,
  selectSlide,
  updateGameAge,
  updateGameEducation,
  updateGameHealth,
  updateGameRelationship,
  updateGameSlide,
} from "./gameSlice"

export const SlideViewer = () => {
  const currentSlide = useAppSelector(selectSlide)
  const data = currentSlide ?? defaultGameData.content[0].slides[0]

  return (
    <Card maxW="sm" height="50vh">
      <CardBody p={0}>
        <Image
          src={data.image}
          alt={data.imageAlt}
          borderRadius="lg"
          objectFit="cover"
          width="100%"
          height="100%"
          maxHeight="20vh"
        />
        <Stack mt="6" spacing="3" p="6" height="10vh">
          <GameHeader text={data.title} />
          <GameText text={data.description} />
        </Stack>
      </CardBody>
      <CardFooter height="10vh">
        <SlideButtonGroup selections={data.selections} />
      </CardFooter>
    </Card>
  )
}

const SlideButtonGroup = (props: { selections: BaseSelection[] }) => {
  if (props.selections.length === 0) {
    const defaultAction: BaseSelection = {
      title: { en: "Next" },
    }
    return <ResetButton />
  }

  return (
    <ButtonGroup spacing="4" mt="6" justifyContent="space-between" width="100%">
      {props.selections.map((selection, index) => (
        <SlideButton key={index} action={selection} />
      ))}
    </ButtonGroup>
  )
}

export const ResetButton = () => {
  const dispatch = useAppDispatch()

  const onReset = () => {
    dispatch(resetGame())
  }

  return (
    <Button onClick={onReset} colorScheme="red" mt="4">
      Reset
    </Button>
  )
}

const SlideButton = (props: { action: BaseSelection }) => {
  // Dispatch
  const dispatch = useAppDispatch()
  const gameData = useAppSelector(state => state.game)

  const icon = lookupGameIcon(props.action?.icon)
  const { setEducationModifier } = useGameTriggerContext()
  const { setHealthModifier } = useGameTriggerContext()
  const { setRelationshipModifier } = useGameTriggerContext()

  const onClick = () => {
    dispatch(incrementGameTurn())
    dispatch(updateGameAge(1))

    console.log("Process action", props.action)
    if (props.action?.affects?.education) {
      dispatch(updateGameEducation(props.action.affects.education))
    }
    if (props.action?.affects?.health) {
      dispatch(updateGameHealth(props.action.affects.health))
    }
    if (props.action?.affects?.relationship) {
      dispatch(updateGameRelationship(props.action.affects.relationship))
    }
    if (props.action?.affects?.age) {
      dispatch(updateGameAge(props.action.affects.age))
    }

    const updatedGameData = processTurn(gameData, props.action)
    console.log("Updated stats", updatedGameData)
    const isGameOver = !checkGameRules(updatedGameData, defaultGameData.rules);
    if (isGameOver) {
      console.log("Game over")
      dispatch(updateGameSlide({
        title: { en: "Game Over" },
        description: { en: "You have died" },
        image: "https://via.placeholder.com/150",
        imageAlt: "placeholder",
        type: "event",
        selections: [],
      }))
      return
    }


    const newSlide = getRandomValidSlide(defaultGameData.content, updatedGameData)
    if (newSlide) {
      console.log("New slide found")
      dispatch(updateGameSlide(newSlide))
    } else {
      console.log("No new slide found")
    }
  }

  const onHoverStart = () => {
    if (props.action?.affects?.education) {
      setEducationModifier(25)
    }
    if (props.action?.affects?.health) {
      setHealthModifier(25)
    }
    if (props.action?.affects?.relationship) {
      setRelationshipModifier(25)
    }
  }

  const onHoverEnd = () => {
    setEducationModifier(0)
    setHealthModifier(0)
    setRelationshipModifier(0)
  }

  return (
    <motion.a
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      onTouchStartCapture={onHoverStart}
      onTouchEndCapture={onHoverEnd}
      onClick={onClick}
    >
      {icon ? (
        <Button aria-label="choice" leftIcon={icon}>
          <GameText text={props.action.title} />
        </Button>
      ) : (
        <Button aria-label="choice">
          <GameText text={props.action.title} />
        </Button>
      )}
    </motion.a>
  )
}
