import { Box } from "@chakra-ui/react"
import { EducationMeter } from "../features/game/EducationMeter"
import { HealthMeter } from "../features/game/HealthMeter"
import { RelationshipMeter } from "../features/game/RelationshipMeter"
import {  SlideViewer } from "../features/game/SlideViewer"
import { CharacterText } from "../features/character/CharacterText"
import { AgeCounter } from "../features/game/AgeCounter"

const GameSlidePage: React.FC = () => {
  return (
    <Box>
      <CharacterText />
      <AgeCounter />
      <Box display="flex" justifyContent="center">
        <Box p={2}>
          <EducationMeter />
        </Box>
        <Box p={2}>
          <HealthMeter />
        </Box>
        <Box p={2}>
          <RelationshipMeter />
        </Box>
      </Box>
      <SlideViewer />
    </Box>
  )
}

export default GameSlidePage
