import type { PayloadAction } from "@reduxjs/toolkit";
import { createAppSlice } from "../../app/createAppSlice";
import type { GameLogicConfig } from "../types";

// Load default from JSON
export const configInitialState: GameLogicConfig = {
  language: "en"
}

export const configSlice = createAppSlice({
  name: "config",
  initialState: configInitialState,
  reducers: create => ({
    editConfigLanguage: create.reducer(
      (state, action: PayloadAction<string>) => {
        state.language = action.payload
      },
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    // State
    selectConfigLanguage: config => config.language
  },
})

// Action creators are generated for each case reducer function.
export const {
 editConfigLanguage
} = configSlice.actions

// Selectors regameed by `slice.selectors` take the root state as their first argument.
export const {
  selectConfigLanguage
} = configSlice.selectors
